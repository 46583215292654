<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">

          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item prop="name" label="电子书名">
                <el-input v-model="queryParams.name" placeholder="请输入电子书名" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item prop="isShow" label="是否展示">
                <el-select v-model="queryParams.isShow" clearable placeholder="请选择是否展示">
                  <el-option
                      v-for="dict in dict.sys_yes_no"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item label="创建日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">{{showSearch ? '隐藏搜索' : '显示搜索'}}</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="name" label="电子书名" show-overflow-tooltip/>

        <el-table-column label="是否展示" width="300">
          <template slot-scope="scope">
            <dict-tag :options="dict.sys_yes_no" :value="scope.row.isShow"></dict-tag>
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="排序" width="200"/>

        <el-table-column prop="createTime" label="创建时间" width="200"/>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>&nbsp;
            <el-button type="text" @click="handleView(scope.row)">查看电子书</el-button>&nbsp;
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" width="500px" top="100">
      <div style="width: 400px">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">

          <el-form-item label="" prop="id">
            <el-input v-show="false" v-model="formData.id"></el-input>
          </el-form-item>

          <el-form-item label="电子书名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入电子书名" clearable/>
          </el-form-item>

          <el-form-item label="是否展示" prop="isShow">
            <el-select v-model="formData.isShow" clearable placeholder="请选择是否展示">
              <el-option
                  v-for="dict in dict.sys_yes_no"
                  :key="Number(dict.dictValue)"
                  :label="dict.dictLabel"
                  :value="Number(dict.dictValue)"/>
            </el-select>
          </el-form-item>

          <el-form-item label="排序" prop="sort">
            <el-input v-model="formData.sort" min="1" max="99999" type="number" placeholder="请输入排序"/>
          </el-form-item>

          <el-form-item label="" prop="fileUrl">
            <el-input v-show="false" v-model="formData.fileUrl"></el-input>
          </el-form-item>

          <el-form-item label="" prop="fileList">
            <el-input v-show="false" v-model="formData.fileList"></el-input>
          </el-form-item>

          <el-form-item label="电子文件" prop="fileId">
            <el-input type="number" v-show="false" v-model="formData.fileId"></el-input>
            <el-upload
                ref="upload"
                :headers="{Authorization: token}"
                :action="uploadUrl"
                :show-file-list="true"
                :file-list="formData.fileList"
                :multiple="false"
                :on-change="handleFileChange"
                :on-success="handleImageSuccess"
                :before-upload="beforeImageUpload"
                :on-remove="handleFileRemove">
            <el-button v-if="!formData.fileId" size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过20MB</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import DictTag from "@/components/DictTag/index.vue";
import store from "@/store";

export default {
  name: "index",
  dicts: ['sys_yes_no'],
  components: {DictTag, CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        name: '',
        isShow: null,
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        name: '',
        isShow: null,
        fileId: null,
        fileUrl: '',
        fileList: [],
        sort: 99,
      },

      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        name: [
          {required: true, message: '请输入电子书名', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        isShow: [
          {required: true, message: '请选择是否展示', trigger: ['blur', 'change']}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.book.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    handleFileRemove(file, fileList) {
      this.formData.fileId = null
      this.formData.fileUrl = ''
    },

    handleFileChange(file, fileList) {
      this.formData.fileList = fileList
    },

    handleView(row) {
      window.open(row.fileUrl)
      //window.open('http://192.168.10.245:8088/artisan/pdf?fileUrl=' + row.fileUrl)
    },

    handleUpdate(row) {
      this.$api.book.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.formData = res
        this.formData.fileList = [{name: res.fileName, url: res.fileUrl}]
        this.$refs.dialog.show()
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除“<b class="color-danger">${row.name}</b>”？`
      }).then(() => {
        this.$api.book.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      if (!this.formData.fileId) {
        this.$message.warning('请上传电子文件')
        this.$refs.dialog.stopLoading()
        return
      }

      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.book.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.book.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.fileId = res.data.id
      this.formData.fileUrl = URL.createObjectURL(file.raw);
    },

    beforeImageUpload(file) {
      const isPdf = file.type === 'application/pdf';
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!isPdf) {
        this.$message.error('上传的文件只能是 PDF 格式!');
      }
      if (!isLt20M) {
        this.$message.error('上传的文件大小不能超过 20MB!');
      }
      return isPdf && isLt20M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .el-upload-dragger .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .el-upload-dragger .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .el-upload-dragger {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  //height: 178px;
  //line-height: 178px;
  text-align: center;
}

</style>
